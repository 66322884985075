const Routes = [
  // Index page
  {
    path: "/",
    asyncComponent: () => import("./pages/Stemwede"),
    master: false,
    options: {
      reloadAll: true,
    },
  },
  {
    path: "/impressum",
    asyncComponent: () => import("./pages/Impressum"),
    master: false,
    options: {
      reloadAll: true,
    },
  },
  {
    name: "tour",
    path: "/:tour",
    asyncComponent: () => import("./pages/Tour"),
    master: true,
    detailRoutes: [
      {
        name: "poi",
        path: "/:tour/:poi",
        asyncComponent: () => import("./pages/POI"),
        options: {
          props: { community: "Stemwede" },
        },
      },
    ],
  },
  // {
  //   name: "NotFound",
  //   path: "(.*)",
  //   component: NotFound
  // }
];

export default Routes;
