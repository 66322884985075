import { App, View } from "framework7-react";
import { useRef } from "react";
import routes from "./Routes";
import store from "./Store";

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/roboto-condensed/300.css";
import "@fontsource/roboto-condensed/400.css";

import consoleArt from "../../components/ConsoleArt";

export default function ErlebenApp() {
  const f7params = {
    name: "erleben.app",
    theme: "auto",
    id: "app.erleben.stemwede.destel",
    store,
    routes,
  };

  const ViewParams = {
    browserHistory: true,
    browserHistorySeparator: "",
    browserHistoryRoot: process.env.URL,
    browserHistoryInitialMatch: true,
    browserHistoryOnLoad: false,
    masterDetailBreakpoint: 1024,
    main: true,
    animate: false,
  };

  const topRef = useRef();
  const scrollRef = useRef();

  consoleArt();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <App {...f7params}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <View {...ViewParams} scrollRef={scrollRef} topRef={topRef} />
    </App>
  );
};