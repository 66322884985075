// Import React
import { createElement } from "react";

// Import ReactDOM
import ReactDOM from "react-dom";

// Import F7 Bundle
import Framework7 from "framework7/lite-bundle";

// Import F7-React Plugin
import Framework7React from "framework7-react";

// Import Main App component
import App from "./erleben/Stemwede/App";

// Import F7 CSS
import "framework7/framework7-bundle.css";

// Import App CSS
import "./erleben/Stemwede/App.css";

// Init F7-React Plugin
Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(createElement(App), document.getElementById("root"));
