/* eslint-disable no-param-reassign,import/no-dynamic-require */
import { createStore } from "framework7";

const store = createStore({
  state: {
    POIs: [],
    Map: {},
  },
  actions: {
    getConfig({ state }, { tour }) {
      // clear state
      state.POIs = [];
      state.Map = {};

      const config = require(`./pages/tours/${tour}.json`);
      state.POIs = config.POIs;
      if (Object.prototype.hasOwnProperty.call(config.Map, "layer")) {
        config.Map.layer.forEach((layer) => {
          if (layer.image && !layer.image.startsWith("/static/"))
            layer.image = require(`./assets/img/${tour}/${layer.image}`);
          return layer;
        });
      }
      state.Map = config.Map;
    },
    clearConfig({ state }) {
      state.POIs = [];
      state.Map = {};
    },
  },
  getters: {
    POIs({ state }) {
      return state.POIs;
    },
    Map({ state }) {
      return state.Map;
    },
  },
});

export default store;
